<template>
    <div class="card">
        <div class="table-container">
            <table class="table create-billing table-width">
                <thead>
                    <tr>
                        <th class=" pl-10 particular">Product</th>
                        <th class="unit_of_measurment">UoM</th>
                        <th class="min_order_qtn">Min. Order Qty</th>
                        <th class="slab_qtn">Slab Qty</th>
                        <th class="bonus_qty">Bonus Qty</th>
                        <th class="offer_start_date">Offer Start Date</th>
                        <th class="offer_end_date">Offer End Date</th>
                        <th class="status">Status</th>
                        <th class="th-action">Action</th>
                    </tr>
                </thead>

                <tbody ref="tableRef">
                    <RowInline
                      v-for="(item, i) in tableItems"
                      :key="i"
                      :item="item"
                      :index="i"
                      @onOpenEditItem="onOpenEditItem"
                      @onDeleteItem="onDeleteItem"
                      @onSaveItem="onSaveItemHandler"
                    />
                </tbody>
            </table>
            <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
        </div>
        <div class="row">
            <div class="col-12 mb-2">
                <div class="add-new-line">
                    <button @click="$emit('addNewItem')" class="btn btn-outline-primary btn-sm">
                        <i class="fas fa-plus"></i>
                        Add new
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import RowInline from '@/components/molecule/company/inventory/sales-offer/InvoiceTableRow.vue';
import useDetectOutsideClick from '@/services/useDetectOutsideClick';
import { ref } from 'vue';
import handlePurchaseAndSales from "@/services/modules/purchase";
import {useRoute} from "vue-router";

const tableRef = ref(null)
const { deleteSalesOfferSetup } = handlePurchaseAndSales()

const props = defineProps({
    tableItems: {
        type: Array
    },
    addNewItem: {
        type: Function
    },
    getSalesOffers: {
        type: Function
    }
})

const route = useRoute();

const emit = defineEmits(['addNewItem', 'onSaveItem']);

useDetectOutsideClick(tableRef, () => {
    resetItemList();
})

const onOpenEditItem = (index) => {
    resetItemList(index);
}

const onSaveItemHandler = (item) => {
    emit('onSaveItem', item);   
}

const onDeleteItem = (index) => {
    const item = props.tableItems[index];

    if (!item.id) {
        props.tableItems.splice(index, 1);
        return;
    }

    deleteSalesOfferSetup(item.id, `?company_id=${route.params.companyId}`);
    props.getSalesOffers();
}

const resetItemList = (selectedIndex = -1) => {
    for (let i = 0; i < props.tableItems.length; i++) {

        if (selectedIndex === i) {
            props.tableItems[i].is_edit = true;
            continue;
        }
        props.tableItems[i].is_edit = false;
    }
}
</script>

<style>
.create-billing> :not(first-child)>* {
    padding-left: 10px !important;
}

.create-billing> :not(caption)>*>* {
    padding: 0.72rem 0.2rem;
}

.particular {
    width: 25%;
}

.card-height {
    min-height: 200px
}

.add-new-line {
    margin-top: 4%;
}
</style>